import { Wistia } from 'wistia_namespace.js';
import { cachedDetect } from 'utilities/detect.js';
import { h, render, Component } from 'preact';
import { unescapeHtml } from 'utilities/core.js';
import { assign } from 'utilities/assign.js';
import { batchFetchData } from 'utilities/batchFetchMediaData.js';
import { anyValuesChanged } from 'utilities/any-values-changed.js';
import { prefetchEngineAndPlugins } from 'utilities/prefetching.js';
import { getShortDescription } from 'utilities/getShortDescription.js';
import { Color } from 'utilities/color.js';
import { secondsConverter } from 'utilities/duration.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import headerFontSizeGw from '../headerFontSizeGw.js';
import { setRouteDataOnUri } from '../uriStateTransformers.js';
import { RawHTMLStub } from '../../shared/RawHTMLStub.jsx';
import { fetchMediaData } from '../fetchGalleryData.js';

const detect = cachedDetect();

const DESCRIPTION_OPACITY_TRANSITION_DURATION = 1000;
const SCALE_TRANSITION_DURATION = 600;

const formatDuration = (totalSeconds) => {
  const { hours, minutes } = secondsConverter(totalSeconds, 'hms');

  if (totalSeconds < 45) {
    return `${Math.round(totalSeconds)} SEC`;
  }

  if (hours) {
    return `${hours} HR ${minutes} MIN`;
  }

  return `${Math.round(totalSeconds / 60)} MIN`;
};

class NarrowMediaCard extends Component {
  constructor(props) {
    super(props);
    const { name } = props;
    this.unescapedName = unescapeHtml(name);
    this.unbinds = [];
    const { galleryData, hashedId, routeStrategyOptions } = this.props;
    const routeData = {
      wchannelid: galleryData.hashedId,
      wmediaid: hashedId,
    };
    this.videoUrl = setRouteDataOnUri(location.href, routeData, routeStrategyOptions);
  }

  state = {
    isHoveringOrFocusing: false,
    isScaledUp: false,
    shortDescription: null,
  };

  maybeInitPopover() {
    const { suppressPopover } = this.props;

    if (!this.didInit && !suppressPopover) {
      this.didInit = true;
      dynamicImport('assets/external/poster.js');
      dynamicImport('assets/external/popover-v3.js').then(() => {
        Wistia.embedPopover(this.props.hashedId, this.getPopoverEmbedOptions()).then((p) => {
          this.popover = p;
        });
      });
    }
  }

  componentDidMount() {
    const { on } = this.props;

    this.maybeInitPopover();

    this.unbinds.push(
      on('colorchange', (color) => {
        if (this.popover) {
          this._color = color;
          this.popover._poster.updateEmbedOptions({ color });
        }
      }),
    );

    this._intersectionObserver = new window.IntersectionObserver(this.onIntersectViewport);
    // eslint-disable-next-line observers/no-missing-unobserve-or-disconnect
    this._intersectionObserver.observe(this.narrowMediaCardRef);
  }

  componentDidUpdate() {
    this.maybeInitPopover();

    const { cardWidth, cardHeight, contentTypeLabel, posterRef, shouldShowVideoDescriptions } =
      this.props;
    const { isHoveringOrFocusing } = this.state;

    if (this.popover && this.popover._poster) {
      const width = cardWidth;
      const height = cardHeight;

      // Because we can calculate width and height without doing a DOM
      // operation (i.e. literally looking at the poster container's width and
      // height), we can save a _ton_ of time on renders by providing them
      // here.
      this.popover._poster.fit({ width, height });

      const popoverEmbedOptions = this.getPopoverEmbedOptions();
      const { playerColor, posterOptions } = popoverEmbedOptions;
      this.popover._poster.updateEmbedOptions(posterOptions);
      this.popover.setContentTypeLabel(contentTypeLabel);
      this.popover.setNavigation(popoverEmbedOptions.navigation);
      this.popover.setPlayerColor(playerColor);
      this.popover.setShouldShowDescription(shouldShowVideoDescriptions);
      this.popover.setShouldShowTranscript(this.getPopoverEmbedOptions().shouldShowTranscript);
      if (posterRef) {
        posterRef(this.popover._poster);
      }
      if (isHoveringOrFocusing) {
        this.fetchDescription();
        this.popover._poster.showPlayButton();
      } else {
        this.popover._poster.hidePlayButton();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.unbinds.forEach((u) => u());
  }

  getPopoverEmbedOptions() {
    const {
      episodeId,
      galleryData,
      galleryEmbedOptions,
      getVideoEmbedOptions,
      headerFontFamily,
      subscribeIsRequired,
      title,
      type,
      viewerIsSubscribed,
    } = this.props;

    // TODO: Once the data change has been out for a while, change this to
    // type === 'Video'. Until that's out, `type` may be undefined.
    const isVideo = type !== 'Audio';
    const videoFeatureOn = isVideo;

    const baseZIndex =
      galleryEmbedOptions.overlayZIndex != null ? galleryEmbedOptions.overlayZIndex : 10000;

    // we want to default to transcript being on in the popoverOverlay. So only an explicit `false`
    // should turn them off
    const shouldShowTranscript =
      galleryEmbedOptions.shouldShowTranscript == null
        ? true
        : galleryEmbedOptions.shouldShowTranscript;

    const embedOptions = assign(
      {
        channel: galleryData.hashedId,
        channelId: galleryData.numericId,
        channelTitle: title,
        container: this.popoverContainer,
        deliveryCdn: galleryEmbedOptions.deliveryCdn,
        episodeId,
        openingIsDisabled: subscribeIsRequired && !viewerIsSubscribed,
        overlayZIndex: baseZIndex + 10,
        playerColor: this._color || galleryEmbedOptions.color,
        podcastLinks: galleryData.podcastLinks,
        popoverContent: 'poster',
        posterOptions: {
          backgroundColor: 'transparent',
          color: this._color || galleryEmbedOptions.color,
          bpbOnHover: isVideo,
          channel: galleryData.hashedId,
          cursorPointer: 'everywhere',
          deliveryCdn: galleryEmbedOptions.deliveryCdn,
          embedHost: galleryEmbedOptions.embedHost,
          fadeInDelay: 0,
          fadeInTime: 1000,
          translateTime: 1000,
          formatDuration,
          monitor: false,
          playButton: isVideo,
          playButtonTabIndex: -1,
          progressIndicator: galleryEmbedOptions.resumable !== false,
          showDuration: true,
          thumbnailFitStrategy: 'cover',
          videoFeature: videoFeatureOn ? 'deferred' : undefined,
        },
        shouldShowTitle: true,
        shouldShowTranscript,
        fontFamily: headerFontFamily,
      },
      getVideoEmbedOptions(),
    );

    // we should only put the resumable option on the popover
    // video if it is explicitly set
    if (galleryEmbedOptions.resumable != null) {
      embedOptions.resumable = galleryEmbedOptions.resumable;
    }

    return embedOptions;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return anyValuesChanged(this.props, nextProps) || anyValuesChanged(this.state, nextState);
  }

  fetchDescription() {
    const { afterBatchFetch, hashedId } = this.props;

    batchFetchData(hashedId, this.getPopoverEmbedOptions()).then((data) => {
      const description = unescapeHtml(data.basic.description);
      this.setState({ shortDescription: getShortDescription(description) });
      if (afterBatchFetch) {
        afterBatchFetch();
      }
    });
  }

  prefetchScripts() {
    const { hashedId } = this.props;

    fetchMediaData(hashedId, this.getPopoverEmbedOptions()).then((mediaData) => {
      prefetchEngineAndPlugins(mediaData, mediaData.embedOptions);
    });
  }

  setNarrowMediaCardRef = (elem) => {
    const { elemRef } = this.props;
    if (elemRef) {
      elemRef(elem);
    }
    this.narrowMediaCardRef = elem;
  };

  onClickNarrowMediaCard = (event) => {
    const { hashedId, onClickVideoCard } = this.props;
    event?.preventDefault();
    onClickVideoCard(hashedId, this.narrowMediaCardRef);
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.onClickNarrowMediaCard();
    }
  };

  onFocusOrMouseEnter = () => {
    if (this.state.shortDescription == null) {
      this.fetchDescription();
    }

    this.prefetchScripts();

    this.setState({
      isHoveringOrFocusing: true,
    });
  };

  onBlurOrMouseLeave = () => {
    this.setState({ isHoveringOrFocusing: false });
  };

  onTransitionEnd = () => {
    // This is important for a nuanced UI interaction, as the viewer moves their
    // into and out of the arrow click target area and the section it's in.
    if (this.state.isHoveringOrFocusing) {
      this.setState({
        isScaledUp: true,
      });
    } else {
      this.setState({
        isScaledUp: false,
      });
    }
  };

  shouldFocusOnScroll() {
    return detect.touchScreen;
  }

  onEnterViewport = () => {
    if (!this.shouldFocusOnScroll()) {
      return;
    }

    this.onScrollOrResize();
    window.addEventListener('scroll', this.onScrollOrResize, { passive: true });
    window.addEventListener('resize', this.onScrollOrResize, { passive: true });
  };

  onIntersectViewport = (entries) => {
    if (entries[0].intersectionRatio > 0 && !this._inViewport) {
      this._inViewport = true;
      this.onEnterViewport();
    } else if (entries[0].intersectionRatio <= 0 && this._inViewport) {
      this._inViewport = false;
      this.onLeaveViewport();
    }
  };

  onLeaveViewport = () => {
    if (!this.shouldFocusOnScroll()) {
      return;
    }

    this.onScrollOrResize();
    window.removeEventListener('scroll', this.onScrollOrResize, { passive: true });
    window.removeEventListener('resize', this.onScrollOrResize, { passive: true });
  };

  onScrollOrResize = () => {
    if (!this.narrowMediaCardRef) {
      // this can happen in specs?
      return;
    }

    const { top } = this.narrowMediaCardRef.getBoundingClientRect();
    const { cardHeight } = this.props;
    const windowHeight = window.innerHeight;
    const cardMidPoint = top + cardHeight / 2;
    const midPointRatio = cardMidPoint / windowHeight;
    const focusableRegionSize = (cardHeight / windowHeight) * 0.8;
    const startFocusAt = cardHeight / windowHeight / 1.7;
    const endFocusAt = startFocusAt + focusableRegionSize;

    const inFocusableArea = midPointRatio >= startFocusAt && midPointRatio < endFocusAt;

    const { isHoveringOrFocusing } = this.state;
    if (inFocusableArea && !isHoveringOrFocusing) {
      this.setState({ isHoveringOrFocusing: true });
    } else if (!inFocusableArea && isHoveringOrFocusing) {
      this.setState({ isHoveringOrFocusing: false });
    }

    const poster = Object(this.popover)._poster || {};
    const posterVideo = Object(poster.controls).video;

    if (posterVideo) {
      if (inFocusableArea) {
        posterVideo.playAndFadeIn();
      } else {
        posterVideo.pauseAndFadeOut();
      }
    }
  };

  popoverStyle() {
    return {
      height: '100%',
      position: 'relative',
      width: '100%',
    };
  }

  scaleWrapperStyle() {
    const { isHoveringOrFocusing } = this.state;
    return {
      transform: isHoveringOrFocusing ? 'scale(1.05)' : 'scale(1.001)',
      // iOS has a rendering glitch that frequently causes the whole card to
      // disappear on focus if we have a transition property here. Disabling
      // it in that context for now.
      transition: detect.ios.version > 0 ? '' : `transform ${SCALE_TRANSITION_DURATION}ms`,
    };
  }

  thumbnailWrapperStyle() {
    const { backgroundColor, cardHeight } = this.props;
    const { isHoveringOrFocusing } = this.state;
    return {
      boxShadow: isHoveringOrFocusing
        ? `0 0 10px 0 ${new Color(backgroundColor).alpha(0.6).toRgba()}`
        : '',
      flex: '0 0 auto',
      height: `${cardHeight}px`,
      overflow: 'hidden',
      position: 'relative',
    };
  }

  nameWrapperStyle() {
    const { backgroundColor } = this.props;
    return {
      backgroundColor: `#${backgroundColor}`,
      flex: '0 1 auto',
      paddingBottom: '.5em',
      paddingTop: '.6em',
    };
  }

  textStyle() {
    const { foregroundColor, headerFontFamily } = this.props;
    return {
      color: `#${foregroundColor}`,
      fontFamily: headerFontFamily,
      fontWeight: 400,
      margin: 0,
    };
  }

  nameStyle() {
    return {
      ...this.textStyle(),
      display: '-webkit-box',
      fontSize: `${headerFontSizeGw(this.props, 4)}px`,
      lineHeight: '1.25em',
      overflow: 'hidden',
      position: 'relative',
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 2,
      whiteSpace: 'normal',
    };
  }

  descriptionStyle() {
    const { isHoveringOrFocusing, shortDescription } = this.state;
    const { shouldShowVideoDescriptions } = this.props;
    return {
      ...this.textStyle(),
      display: shouldShowVideoDescriptions ? '-webkit-box' : 'none',
      fontSize: headerFontSizeGw(this.props, 3.2),
      opacity: isHoveringOrFocusing && shortDescription ? 1 : 0,
      overflow: 'hidden',
      transition: `opacity ${isHoveringOrFocusing ? DESCRIPTION_OPACITY_TRANSITION_DURATION : 0}ms`,
      webkitBoxOrient: 'vertical',
      webkitLineClamp: 3,
      whiteSpace: 'normal',
    };
  }

  descriptionAnchorStyle() {
    return {
      position: 'relative',
    };
  }

  descriptionWrapperStyle() {
    const { backgroundColor } = this.props;
    return {
      background: `#${backgroundColor}`,
      paddingBottom: '0.9em',
      position: 'absolute',
      width: '100%',
    };
  }

  cardStyle() {
    const { cardWidth, style } = this.props;
    const { isHoveringOrFocusing } = this.state;

    return {
      cursor: 'pointer',
      display: 'block',
      flex: '0 0 auto',
      outline: 'none',
      position: 'relative',
      width: `${cardWidth}px`,
      zIndex: isHoveringOrFocusing ? 1 : '',
      ...style,
    };
  }

  render() {
    const { shortDescription } = this.state;

    return (
      <a
        class="w-video-card"
        href={this.videoUrl}
        onBlur={this.onBlurOrMouseLeave}
        onClick={this.onClickNarrowMediaCard}
        onFocus={this.onFocusOrMouseEnter}
        onKeyDown={this.onKeyDown}
        onMouseEnter={detect.hoverIsNatural ? this.onFocusOrMouseEnter : null}
        onMouseLeave={detect.hoverIsNatural ? this.onBlurOrMouseLeave : null}
        ref={this.setNarrowMediaCardRef}
        style={this.cardStyle()}
        tabIndex={'0'}
      >
        <div style={this.scaleWrapperStyle()}>
          <div
            class="w-video-card__thumbnail-wrapper"
            onTransitionEnd={this.onTransitionEnd}
            style={this.thumbnailWrapperStyle()}
          >
            <RawHTMLStub
              class="wistia_popover"
              stubRef={(el) => (this.popoverContainer = el)}
              style={this.popoverStyle()}
            />
          </div>
          <div style={this.nameWrapperStyle()}>
            <h3 style={this.nameStyle()} title={this.unescapedName}>
              {this.unescapedName}
            </h3>
          </div>
          <div style={this.descriptionAnchorStyle()}>
            <div style={this.descriptionWrapperStyle()}>
              {shortDescription && (
                <h4
                  class="w-video-card__description"
                  style={this.descriptionStyle()}
                  title={shortDescription}
                >
                  {shortDescription}
                </h4>
              )}
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default NarrowMediaCard;
