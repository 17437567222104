import { Wistia } from 'wistia_namespace.js';
import EmbedMatcher from '../../shared/embedding/EmbedMatcher.js';

if (!Wistia._galleryMatcher) {
  Wistia._galleryMatcher = new EmbedMatcher({
    selector: '.wistia_gallery, .wistia_channel',
    propName: 'wistiaGallery',
  });
}

export default Wistia._galleryMatcher;
