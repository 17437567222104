import { Wistia } from 'wistia_namespace.js';
import { assign } from 'utilities/obj.js';
import { initializeSentry } from 'utilities/sentryUtils.ts';
import AsyncEmbedInitializer from '../../shared/embedding/AsyncEmbedInitializer.js';
import embedGallery from '../embedGallery.js';

if (!Wistia._galleryInitializer) {
  performance.mark('channel_embed_start');

  Wistia._galleryInitializer = new AsyncEmbedInitializer({
    type: 'gallery',
    selector: '.wistia_gallery, .wistia_channel',
    propName: 'wistiaGallery',
    embedFn: (container, hashedId, options) => {
      return embedGallery(hashedId, assign({ container }, options));
    },
  });

  initializeSentry();
}

export default Wistia._galleryInitializer;
