import { Wistia } from 'wistia_namespace.js';
import { queryParamsToObject } from 'utilities/url.js';
import { cast } from 'utilities/obj.js';

const ASYNC_CLASS_REGEXP = /wistia_async_([^\s]+)/;

const generateSequentialDomIdWithPrefix = (prefix) => {
  let i = 1;
  // Put a ridiculously high cap--but still a cap--on how many embeds there can
  // be.
  while (i < 1000000) {
    const candidate = `${prefix}-${i}`;
    if (!document.getElementById(candidate)) {
      return candidate;
    }
    i += 1;
  }
};

const hashedIdFromClassName = (className) => {
  if (!className) {
    return null;
  }
  const matches = className.match(ASYNC_CLASS_REGEXP);
  return matches && matches[1];
};

const optionsFromClassArray = (classArray) => {
  const optionClasses = classArray
    .map((klass) => (klass.indexOf('=') > 0 ? klass : null))
    .filter(Boolean);
  return cast(queryParamsToObject(optionClasses.join('&')));
};

if (!Wistia.AsyncEmbedInitializer) {
  class AsyncEmbedInitializer {
    constructor(options) {
      this._type = options.type;
      this._selector = options.selector;
      this._propName = options.propName;
      this._embedFn = options.embedFn;
    }

    findAsyncContainers(rootElems = [document]) {
      const result = [];
      rootElems.forEach((rootElem) => {
        const containersInRoot = rootElem.querySelectorAll(this._selector);
        Array.prototype.slice.call(containersInRoot).forEach((container) => {
          if (ASYNC_CLASS_REGEXP.test(container.className)) {
            result.push(container);
          }
        });
      });
      return result;
    }

    isContainerInitialized = (container) => {
      return Boolean(container[this._propName]);
    };

    isContainerNotInitialized = (container) => {
      return !this.isContainerInitialized(container);
    };

    initEmbeds(rootElems) {
      const containers = this.findAsyncContainers(rootElems).filter(this.isContainerNotInitialized);

      return Promise.all(
        containers.map((container) => {
          const hashedId = hashedIdFromClassName(container.className);
          const options = optionsFromClassArray(Array.prototype.slice.call(container.classList));
          if (!container.id) {
            container.id = generateSequentialDomIdWithPrefix(`wistia-${this._type}-${hashedId}`);
          }

          // This property always starts out as a Promise. When the Promise
          // resolves, the property becomes the apiHandle itself. Therefore,
          // if someone is expecting it to always be a Promise, they can do
          // Promise.resolve(container.propName).then(...).
          const embedPromise = Promise.resolve(this._embedFn(container, hashedId, options)).then(
            (apiHandle) => {
              if (container[this._propName] !== embedPromise) {
                // If the prop is no longer embedPromise, then that means we've
                // either started another embed process or killed the current one.
                // Either way, we shouldn't clobber the prop since we no longer
                // have rights to it.
                return;
              }
              container[this._propName] = apiHandle;
              return apiHandle;
            },
          );
          container[this._propName] = embedPromise;
          return embedPromise;
        }),
      );
    }
  }
  Wistia.AsyncEmbedInitializer = AsyncEmbedInitializer;
}

export default Wistia.AsyncEmbedInitializer;
