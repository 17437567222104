import { Wistia } from 'wistia_namespace.js';
import AsyncEmbedDOMWatcher from '../../shared/embedding/AsyncEmbedDOMWatcher.js';
import galleryInitializer from './galleryInitializer.js';

if (!Wistia._galleryWatcher) {
  Wistia._galleryWatcher = new AsyncEmbedDOMWatcher({
    type: 'gallery',
    selector: '.wistia_gallery, .wistia_channel',
    initFn: () => {
      galleryInitializer.initEmbeds();
    },
  }).watch();
}

export default Wistia._galleryWatcher;
