import { Wistia } from 'wistia_namespace.js';

if (!Wistia.EmbedMatcher) {
  class EmbedMatcher {
    constructor(options) {
      this._selector = options.selector;
      this._propName = options.propName;
    }

    all() {
      return this.getInitializedContainers().map((c) => c[this._propName]);
    }

    find(matcher) {
      const container = this.findContainer(matcher);
      if (container) {
        return container[this._propName];
      }
    }

    matches(propVal, matcher) {
      return this.find(matcher) === propVal;
    }

    getInitializedContainers() {
      // Get containers what have an API handle, and in particular have a handle
      // that can be operated on now (i.e. it's not a promise).
      // See initEmbeds in AsyncEmbedInitializer for more context.
      return Array.prototype.slice
        .call(document.querySelectorAll(this._selector))
        .filter((container) => {
          return container[this._propName] && !container[this._propName].then;
        });
    }

    findContainer(matcher) {
      let container;

      if (matcher == null) {
        container = this.findFirst();
      }

      const typeOfMatcher = typeof matcher;
      if (matcher instanceof HTMLElement) {
        container = matcher;
      } else if (typeOfMatcher === 'number') {
        container = this.findByIndex(matcher);
      } else if (typeOfMatcher === 'string') {
        container = this.findByString(matcher);
      }

      return container;
    }

    findFirst() {
      return this.getInitializedContainers()[0];
    }

    findByIndex(i) {
      const containers = this.getInitializedContainers();
      if (i < 0) {
        i = containers.length + i;
      }
      return containers.slice(i, i + 1)[0];
    }

    findByString(str) {
      const containers = this.getInitializedContainers();
      const container =
        this.findContainerByDomId(str, containers) ||
        this.findContainerByHashedIdPrefix(str, containers) ||
        this.findContainerByDomIdPrefix(str, containers);
      return container;
    }

    findContainerByDomId(containerId, containers) {
      let matchedContainer;
      containers.some((container) => {
        if (container.id === containerId) {
          matchedContainer = container;
        }

        return container.id === containerId;
      });
      return matchedContainer;
    }

    findContainerByHashedIdPrefix(prefix, _containers) {
      let matchedContainer;
      this.getInitializedContainers().some((container) => {
        if (container[this._propName].hashedId().indexOf(prefix) === 0) {
          matchedContainer = container;
        }

        return container[this._propName].hashedId().indexOf(prefix) === 0;
      });
      return matchedContainer;
    }

    findContainerByDomIdPrefix(prefix, _containers) {
      let matchedContainer;
      this.getInitializedContainers().some((container) => {
        if (container.id && container.id.indexOf(prefix) === 0) {
          matchedContainer = container;
        }

        return container.id && container.id.indexOf(prefix) === 0;
      });
      return matchedContainer;
    }
  }

  Wistia.EmbedMatcher = EmbedMatcher;
}

export default Wistia.EmbedMatcher;
