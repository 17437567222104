import { Wistia } from 'wistia_namespace.js';
import { h, render } from 'preact';
import { countMetric } from 'utilities/simpleMetrics.js';
import { dynamicImport } from 'utilities/dynamicImport.ts';
import { defineControl } from '../shared/control_definitions.js';

if (!Wistia.SubscribeControl) {
  const controlMultiplierBasedOnVideo = (video, normalRange) => {
    const vidWidth = video.videoWidth();
    const [lowerCutoff, upperCutoff] = normalRange;
    if (vidWidth < lowerCutoff) {
      return vidWidth / lowerCutoff;
    }
    if (vidWidth > upperCutoff) {
      return vidWidth / upperCutoff;
    }
    return 1;
  };

  class SubscribeControl {
    constructor(video) {
      this.video = video;
    }

    buttonStyle() {
      const { controlsAreVisible } = this.props;
      const scale = this.scale();
      return {
        background: 'rgba(0,0,0,.8)',
        borderRadius: 0,
        bottom: 0,
        color: '#fff',
        cursor: 'pointer',
        fontFamily: 'Helvetica, Arial',
        fontSize: `${13 * scale}px`,
        opacity: controlsAreVisible ? 1 : 0,
        padding: `${6 * scale}px ${10 * scale}px`,
        position: 'absolute',
        right: controlsAreVisible ? 0 : '-99999em',
        transition: `opacity 250ms, right 0 ease ${controlsAreVisible ? 0 : 250}ms`,
      };
    }

    countMetric(key) {
      try {
        countMetric(`player/subscribe-control/${key}`, 1, this.metricPayload());
      } catch (e) {
        // An exception in counting a metric should never cause anything else
        // to fail.
        setTimeout(() => {
          throw e;
        }, 0);
      }
    }

    destroy() {
      render(<nothing />, this.rootElem);
    }

    metricPayload() {
      return {
        hashedId: this.video.hashedId(),
        location: location.origin + location.pathname,
      };
    }

    mount(rootElem) {
      this.rootElem = rootElem;
      this.renderSubscribe();
      dynamicImport('assets/external/interFontFace.js');
      this.countMetric('mount');
    }

    onClick = (e) => {
      const { onClickSubscribe } = this.video.embedOptions();
      if (onClickSubscribe) {
        onClickSubscribe(e);
      }
      this.countMetric('click');
    };

    onControlPropsUpdated(prevProps) {
      const propsWeCareAbout = ['controlsAreVisible', 'videoWidth'];
      const anyPropChanged = propsWeCareAbout.some((prop) => {
        return this.props[prop] !== prevProps[prop];
      });
      if (anyPropChanged) {
        this.renderSubscribe();
      }
    }

    renderSubscribe() {
      render(
        <button
          class="w-css-reset w-vulcan-v2-button"
          onClick={this.onClick}
          style={this.buttonStyle()}
          type="button"
        >
          Subscribe
        </button>,
        this.rootElem,
      );
    }

    scale() {
      return Math.min(1.3, Math.max(0.3, controlMultiplierBasedOnVideo(this.video, [640, 960])));
    }
  }

  SubscribeControl.shouldMount = (video) => {
    return video.embedOptions().subscribeButton;
  };
  SubscribeControl.handle = 'subscribe';
  SubscribeControl.type = 'above-control-bar';
  SubscribeControl.sortValue = 2000;

  Wistia.SubscribeControl = SubscribeControl;

  window._wq = window._wq || [];
  window._wq.push((Wistia) => {
    defineControl(SubscribeControl);
  });
}
