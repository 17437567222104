import { Wistia } from 'wistia_namespace.js';
import { globalEventLoop } from 'utilities/event_loop.js';
import { onDocReady } from 'utilities/docReady.js';
import { throttle } from 'utilities/throttle.js';
import { cachedDetect } from 'utilities/detect.js';
import { wlog } from 'utilities/wlog.js';

const detect = cachedDetect();

if (!Wistia.AsyncEmbedDOMWatcher) {
  class AsyncEmbedDOMWatcher {
    constructor(options) {
      this._type = options.type;
      this._selector = options.selector;
      this._initFn = options.initFn;
      this._pollInterval = options.pollInterval || 500;
      this._slowPollInterval = options.slowPollInterval || 2000;

      const throttleInterval = options.throttleInterval || 500;
      this._throttledInitFn = throttle(throttleInterval, this._initFn);
    }

    pollLoopName() {
      return `poll_for_${this._type}_embeds`;
    }

    pollForNewEmbeds(interval = this._pollInterval) {
      globalEventLoop.add(this.pollLoopName(), interval, this._initFn);
    }

    slowPollForNewEmbeds() {
      return this.pollForNewEmbeds(this._slowPollInterval);
    }

    stopPollingForNewEmbeds() {
      globalEventLoop.remove(this.pollLoopName());
    }

    // Mutation observers efficiently report on changes to the DOM. We define one
    // for the whole body, find any injected wistia embed candidates, then
    // asynchronously set them up. We do it asynchronously so that a js framework
    // has some leeway as to when it evals inline javascript, which might include
    // embed configuration.
    observeDOMAdditionsForNewEmbeds() {
      if (this._observer) {
        // already observing
        return;
      }

      this._observer = new MutationObserver(this.handleMutations);

      const observerConfig = { subtree: true, childList: true };
      if (detect.ios.version > 0) {
        onDocReady(() => {
          this._observer.observe(document.body, observerConfig);
        });
      } else {
        try {
          this._observer.observe(document.body, observerConfig);
        } catch (e) {
          // It's possible to call observe() too early. If this happens, let's fall
          // back to calling it on doc ready.
          wlog.notice(e);
          onDocReady(() => {
            this._observer.observe(document.body, observerConfig);
          });
        }
      }
    }

    handleMutations = (mutations) => {
      let matchedSelector = false;
      let addedNodes = false;

      mutations.forEach((mutation) => {
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          addedNodes = true;
        }
        Array.prototype.slice.call(mutation.addedNodes || []).forEach((node) => {
          if (node.matches && node.matches(this._selector)) {
            matchedSelector = true;
          }
        });
      });

      if (matchedSelector) {
        setTimeout(this._initFn, 10);
      }

      // Not all valid embed codes will be included in the mutations. To deal
      // with this, we use any injection as a proxy to search the entire page
      // for uninitialized embeds. We throttle this so it's no worse than the
      // 500ms polling we do in browsers that don't support mutations. That's
      // for the case where pages are constantly injecting elements.
      if (addedNodes) {
        setTimeout(() => {
          this._throttledInitFn();
        }, 0);
      }
    };

    stopObservingDOMAdditionsForNewEmbeds() {
      if (!this._observer) {
        return;
      }

      this._observer.disconnect();
      this._observer = undefined;
    }

    watch() {
      if (detect.mutationObserver) {
        // Most of the time, new embeds would be simply injected. However, when
        // using Virtual DOM, or intentionally changing the class property to init,
        // it's possible a node is just modified such that it matches an
        // uninitialized embed. To deal with that, we fall back to an extra slow
        // poll and treat the "init on additions" as an optimization for the usual
        // case.
        this.observeDOMAdditionsForNewEmbeds();
        this.slowPollForNewEmbeds();
      } else {
        this.pollForNewEmbeds();
      }
      return this;
    }

    stopWatchingForNewEmbeds() {
      this.stopObservingDOMAdditionsForNewEmbeds();
      this.stopPollingForNewEmbeds();
    }
  }

  Wistia.AsyncEmbedDOMWatcher = AsyncEmbedDOMWatcher;
}

export default Wistia.AsyncEmbedDOMWatcher;
