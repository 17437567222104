import { h, render } from 'preact';
import { standardSvgAttrs } from 'utilities/svg_boilerplate.js';

export const CloseIcon = ({ color }) => {
  return (
    <svg {...standardSvgAttrs({ width: 24, height: 24, ariaHidden: true })}>
      <g stroke="none" strokeWidth="1" fill={`${color}` || '#fff'} fillRule="evenodd">
        <path d="M18.643128,20.7643515 C19.228878,21.3502515 20.178678,21.3502515 20.764428,20.7643515 C21.350178,20.1786015 21.350178,19.2288015 20.764428,18.6430515 L14.121378,12.0000015 L20.764428,5.3569065 C21.350178,4.7711115 21.350178,3.8213715 20.764428,3.2355765 C20.178678,2.6497965 19.228878,2.6497965 18.643128,3.2355765 L12.000018,9.8786715 L5.356893,3.2355465 C4.771098,2.6497515 3.821358,2.6497515 3.235563,3.2355465 C2.649783,3.8213265 2.649783,4.7710815 3.235563,5.3568615 L9.878703,12.0000015 L3.235578,18.6430515 C2.649783,19.2289515 2.649783,20.1786015 3.235578,20.7645015 C3.821358,21.3502515 4.771113,21.3502515 5.356893,20.7645015 L12.000018,14.1213165 L18.643128,20.7643515 Z" />
      </g>
    </svg>
  );
};
